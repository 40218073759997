import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Rubber Revolution Tires
			</title>
			<meta name={"description"} content={"Drive the Extra Mile with Confidence!"} />
			<meta property={"og:title"} content={"Home | Rubber Revolution Tires"} />
			<meta property={"og:description"} content={"Drive the Extra Mile with Confidence!"} />
			<meta property={"og:image"} content={"https://plexiastark.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://plexiastark.com/img/2761318.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://plexiastark.com/img/2761318.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://plexiastark.com/img/2761318.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://plexiastark.com/img/2761318.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://plexiastark.com/img/2761318.png"} />
			<meta name={"msapplication-TileImage"} content={"https://plexiastark.com/img/2761318.png"} />
		</Helmet>
		<Components.Header />
		<Section
			md-justify-content="center"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.1) 67.71%,rgba(4, 8, 12, 0.1) 100%),url(https://plexiastark.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			sm-padding="40px 0 40px 0"
			quarkly-title="Hero-24"
			padding="0px 0 0px 0"
			md-align-items="center"
		>
			<Override
				slot="SectionContent"
				max-width="none"
				width="100%"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="flex-end"
				align-items="flex-end"
			/>
			<Box
				display="flex"
				align-items="flex-start"
				justify-content="center"
				padding="36px 48px 56px 48px"
				background="linear-gradient(120.99deg, #04080C 0%, rgba(25, 30, 34, 0.7) 100%)"
				flex-direction="column"
				width="30%"
				lg-width="50%"
				md-width="60%"
				sm-width="90%"
			>
				<Text
					lg-width="100%"
					lg-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="0px 0px 17px 0px"
					color="--light"
					font="--headline2"
				>
					Rubber Revolution Tires
				</Text>
				<Text
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Welcome to Rubber Revolution Tires, your ultimate destination for top-notch tire services and products. Whether you're gearing up for a road trip or ensuring your daily commute is as smooth as possible, we've got you covered. Our team is dedicated to providing you with professional advice and exceptional service, ensuring your vehicle is equipped to take on any adventure.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 100px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				as="h2"
				margin="8px 0px 32px"
				font="--headline2"
				color="--dark"
				width="100%"
			>
				Why Choose Rubber Revolution?
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--dark"
				padding="0px 16px 0px 16px"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 16px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				At Rubber Revolution Tires, we understand that your vehicle is more than just a mode of transportation, it's a part of your daily life. That's why we offer:
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 0px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Expertise You Can Trust: Our technicians are not only passionate about tires – they're experts in their field, ready to guide you in finding the perfect fit for your vehicle and driving needs.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Quality Beyond Question: We stock a wide selection of tires from leading manufacturers, ensuring durability, safety, and performance.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-margin="0px 0px 16px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				Service with a Smile: We believe in making your tire buying and servicing experience as pleasant as possible. Expect friendly faces and a hassle-free process every step of the way.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Transparent Pricing: With us, what you see is what you get. Enjoy competitive pricing without hidden fees.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://plexiastark.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ready to elevate your driving experience? Visit us, give us a call, or drop an email. We're here to answer any questions and get you back on the road, safely and swiftly.
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://plexiastark.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});